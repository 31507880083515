import React, { useState } from 'react'

export const ThemeContext = React.createContext({
  isDarkMode: false,
  switchTheme: () => {},
})

const ThemeProvider = ({ children }) => {
  const [darkMode, setDarkMode] = useState(
    JSON.parse(localStorage.getItem('Mode')),
  )

  const switchTheme = () => {
    if (JSON.parse(localStorage.getItem('Mode')) === true) {
      setDarkMode(false)
      localStorage.setItem('Mode', JSON.stringify(false))
    } else {
      setDarkMode(true)
      localStorage.setItem('Mode', JSON.stringify(true))
    }
  }

  return (
    <ThemeContext.Provider
      value={{
        isDarkMode: darkMode,
        switchTheme,
      }}
    >
      {children}
    </ThemeContext.Provider>
  )
}

export default ThemeProvider
