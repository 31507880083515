import React, { useContext } from 'react'
import './whatislong.css'
import tokenomicsIcon from '../../assets/images/tokenomics.svg'
import governanceIcon from '../../assets/images/governance.svg'
import roadmapIcon from '../../assets/images/roadmap.svg'
import { ThemeContext } from '../../state/ThemeContext'

const WhatIsLONG = () => {
  const { isDarkMode } = useContext(ThemeContext)

  return (
    <div className="key-features-container">
      <h2 className="bold key-feats-title text-center mt-5 mb-3 what-is-long-title">
        What is LONG Token?
      </h2>

      <p
        className={`key-feats-subtitle text-center mb-5 ${
          isDarkMode ? 'text-muted-dark-mode' : 'text-muted'
        }`}
      >
        As a <strong>native governance and utility token,</strong> LONG is a
        lifeblood of the entire LongTerm Finance ecosystem.
      </p>

      <div className="key-features-long mb-5">
        <div className="key-feature">
          <div className="key-feat-icon text-center">
            <img
              width="100"
              height="150"
              src={tokenomicsIcon}
              alt="Tokenomics"
            />
          </div>
          <h4 className="key-feat-title mt-3">Tokenomics</h4>
          <p className="key-feat-desc">
            LONG features robust and{' '}
            <strong>carefully designed tokenomics</strong>, with the{' '}
            <strong>max supply of 10 million LONG tokens,</strong> roughly 2
            thirds of which are distributed directly to platform users through
            liquidity rewards.
          </p>
        </div>

        <div className="key-feature">
          <div className="key-feat-icon text-center">
            <img
              width="100"
              height="150"
              src={governanceIcon}
              alt="Governance"
            />
          </div>
          <h4 className="key-feat-title mt-3">Governance</h4>
          <p className="key-feat-desc">
            <strong>Vote, propose, decide and debate</strong> changes to the protocol,
            with each LONG token representing 1 vote.
            <strong>Voting is a crucial part</strong> of keeping the platform as
            decentralized and as safe as possible for everyone.
          </p>
        </div>

        <div className="key-feature key-feature-roadmap-row">
          <div className="key-feat-icon text-center">
            <img
              width="100"
              height="150"
              src={roadmapIcon}
              alt="Future Plans"
            />
          </div>
          <h4 className="key-feat-title mt-3">Future Plans</h4>
          <p className="key-feat-desc">
            ALTS is only the beginning for the LongTerm Finance.{' '}
            <strong>
              Our long-term vision is to become the{' '}
              <span style={{ fontStyle: 'italic' }}>
                Vanguard of the crypto industry
              </span>
            </strong>{' '}
            by offering various simple, low-cost & passive investment strategies
            in the form of token indices. Visit{' '}
            <a
              className="desc-link bold"
              target="_blank"
              rel="noreferrer"
              href="https://docs.long-term.finance/protocol/future-plans"
            >
              our future plans docs
            </a>{' '}
            to learn more.
          </p>
        </div>
      </div>

      <div className="key-feature key-feature-roadmap-container">
        <div className="key-feature key-feature-roadmap">
          <div className="key-feat-icon text-center">
            <img
              width="100"
              height="150"
              src={roadmapIcon}
              alt="Future Plans"
            />
          </div>
          <h4 className="key-feat-title mt-3">Future Plans</h4>
          <p className="key-feat-desc">
            ALTS is only the beginning for the LongTerm Finance.{' '}
            <strong>
              Our long-term vision is to become the{' '}
              <span style={{ fontStyle: 'italic' }}>
                Vanguard of the crypto industry
              </span>
            </strong>{' '}
            by offering various simple, low-cost & passive investment strategies
            in the form of token indices. Visit{' '}
            <a
              className="desc-link bold"
              target="_blank"
              rel="noreferrer"
              href="https://docs.long-term.finance/protocol/future-plans"
            >
              our future plans docs
            </a>{' '}
            to learn more.
          </p>
        </div>
      </div>
    </div>
  )
}

export default WhatIsLONG
