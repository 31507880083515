import React, { useState, useContext } from 'react'
import { Link } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import './navbar.css'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'
import List from '@material-ui/core/List'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import MenuIcon from '@material-ui/icons/Menu'
import Button from '@material-ui/core/Button'
import transparentPNGLogo from '../../assets/images/banner.png'
import { ThemeContext } from '../../state/ThemeContext'

const Navbar = () => {
  const { switchTheme, isDarkMode } = useContext(ThemeContext)

  const mobileHeader = useMediaQuery({ query: '(max-width: 992px)' })

  const MoonIcon = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 24 24"
        fill={isDarkMode ? 'none' : '#000'}
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M21 12.79A9 9 0 1 1 11.21 3 7 7 0 0 0 21 12.79z"></path>
      </svg>
    )
  }

  const SunIcon = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <circle cx="12" cy="12" r="5"></circle>
        <line x1="12" y1="1" x2="12" y2="3"></line>
        <line x1="12" y1="21" x2="12" y2="23"></line>
        <line x1="4.22" y1="4.22" x2="5.64" y2="5.64"></line>
        <line x1="18.36" y1="18.36" x2="19.78" y2="19.78"></line>
        <line x1="1" y1="12" x2="3" y2="12"></line>
        <line x1="21" y1="12" x2="23" y2="12"></line>
        <line x1="4.22" y1="19.78" x2="5.64" y2="18.36"></line>
        <line x1="18.36" y1="5.64" x2="19.78" y2="4.22"></line>
      </svg>
    )
  }

  const Slider = () => {
    return (
      <span className={!mobileHeader && 'ml-2 mr-3'}>
        <button
          type="button"
          onClick={switchTheme}
          className={`mode-switcher ${!isDarkMode && 'mode-switcher-moon'} ${
            mobileHeader && 'ml-3'
          }`}
        >
          {isDarkMode ? <SunIcon /> : <MoonIcon />}
        </button>
      </span>
    )
  }

  const useStyles = makeStyles({
    list: {
      width: 250,
    },
    fullList: {
      width: 'auto',
    },
  })

  function SwipeableTemporaryDrawer() {
    const classes = useStyles()
    const [state, setState] = useState({
      top: false,
      left: false,
      bottom: false,
      right: false,
    })

    const toggleDrawer = (anchor, open) => (event) => {
      if (
        event &&
        event.type === 'keydown' &&
        (event.key === 'Tab' || event.key === 'Shift')
      ) {
        return
      }

      setState({ ...state, [anchor]: open })
    }

    const list = (anchor) => (
      <div
        className={clsx(classes.list, {
          [classes.fullList]: anchor === 'top' || anchor === 'bottom',
        })}
        role="presentation"
        onClick={toggleDrawer(anchor, false)}
        onKeyDown={toggleDrawer(anchor, false)}
        style={{
          backgroundColor: isDarkMode && '#0d1014',
          paddingBottom: isDarkMode && mobileHeader && '250vw',
        }}
      >
        <List className="mobile-menu">
          <Link
            to="/"
            className={`nav-link ${
              isDarkMode && 'nav-link-dark-mode'
            } nav-link-mobile`}
          >
            Home
          </Link>
          <Link
            to="/faq"
            className={`nav-link ${
              isDarkMode && 'nav-link-dark-mode'
            } nav-link-mobile`}
          >
            FAQ
          </Link>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://docs.long-term.finance/protocol/litepaper"
            className={`nav-link ${
              isDarkMode && 'nav-link-dark-mode'
            } nav-link-mobile`}
          >
            Litepaper
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://docs.long-term.finance"
            className={`nav-link ${
              isDarkMode && 'nav-link-dark-mode'
            } nav-link-mobile`}
          >
            Docs
          </a>
        </List>
        <Slider />
        {
          <List className="mobile-menu">
            <button
              className="btn shadow-btn mt-3 header-btn"
              type="button"
              style={{ marginLeft: '22px' }}
            >
              <a
                target="_blank"
                rel="noreferrer"
                className="btn-link"
                href="https://app.long-term.finance"
                style={{ fontWeight: 'bold' }}
              >
                Enter App
              </a>
            </button>
          </List>
        }
      </div>
    )

    const styles = {
      largeIcon: {
        width: 35,
        height: 35,
        position: 'relative',
        bottom: 3,
      },
    }

    return (
      <div>
        {['right'].map((anchor) => (
          <React.Fragment key={anchor}>
            <Button
              style={{ backgroundColor: isDarkMode ? '#0d1014' : '#fafbfd' }}
              onClick={toggleDrawer(anchor, true)}
            >
              <MenuIcon style={styles.largeIcon} className="menu-icon" />
            </Button>
            <SwipeableDrawer
              anchor={'right'}
              open={state[anchor]}
              onClose={toggleDrawer(anchor, false)}
              onOpen={toggleDrawer(anchor, true)}
            >
              {list(anchor)}
            </SwipeableDrawer>
          </React.Fragment>
        ))}
      </div>
    )
  }

  return (
    <nav className={`navbar ${isDarkMode && 'navbar-dark-mode'}`}>
      {!mobileHeader ? (
        <React.Fragment>
          <div className="logo">
            <Link to="/">
              <img
                className="logo-img"
                src={transparentPNGLogo}
                alt="LongTerm Logo"
                width="177"
                height="82"
              />
            </Link>
          </div>
          <div className="nav-links">
            <Link
              to="/"
              className={`nav-link ${isDarkMode && 'nav-link-dark-mode'}`}
            >
              Home
            </Link>
            <Link
              to="/faq"
              className={`nav-link ${isDarkMode && 'nav-link-dark-mode'}`}
            >
              FAQ
            </Link>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://docs.long-term.finance/protocol/litepaper"
              className={`nav-link ${isDarkMode && 'nav-link-dark-mode'}`}
            >
              Litepaper
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://docs.long-term.finance"
              className={`nav-link ${isDarkMode && 'nav-link-dark-mode'}`}
            >
              Docs
            </a>
            <Slider />
            <button className="btn shadow-btn mr-4 header-btn" type="button">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://app.long-term.finance"
                className="btn-link bold header-btn"
                style={{ fontWeight: 'bold' }}
              >
                Enter App
              </a>
            </button>
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div
            className="logo"
            style={{
              position: 'relative',
              right: 25,
            }}
          >
            <Link to="/">
              <img
                className="logo-img logo-img-header"
                src={transparentPNGLogo}
                alt="LongTerm Logo"
                style={{
                  margin: 'auto',
                  position: 'relative',
                  right: '20',
                  marginLeft: '40px',
                }}
                width="177"
                height="82"
              />
            </Link>
          </div>
          <SwipeableTemporaryDrawer />
        </React.Fragment>
      )}
    </nav>
  )
}

export default Navbar
