import React, { useState } from 'react'
import './hero.css'
import heroImg from '../../assets/images/hero.png'
import { useMediaQuery } from 'react-responsive'

const Hero = () => {
  const isSmallScreen = useMediaQuery({ query: '(max-width: 350px)' })

  const [mouseOver, setMouseOver] = useState(false)
  const handleOnMouseOver = () => setMouseOver(true)
  const handleOnMouseOut = () => setMouseOver(false)

  return (
    <div className="intro-screen">
      <div className="intro-text">
        <h1 className="intro-title text-left mb-5 bold">
          LongTerm Finance: Go LONG on ALTS
        </h1>

        <p className="intro-description mb-5">
          <strong>Simplify your crypto investing with ALTS,</strong> a token
          that tracks the entire crypto market excluding BTC and ETH.
          <br /> Easily diversify your portfolio and at the same time{' '}
          <strong>
            get the altcoin market's superior performance in one click.
          </strong>
        </p>

        <div className="intro-buttons">
          <button
            className="btn shadow-btn mr-5 hero-btn"
            type="button"
            style={{
              width: isSmallScreen && '100px',
              fontSize: isSmallScreen && '1.1rem',
            }}
          >
            <a
              target="_blank"
              rel="noreferrer"
              className="btn-link"
              href="https://app.long-term.finance"
              style={{
                fontSize: isSmallScreen ? '0.97rem' : '1.2rem',
              }}
            >
              Enter App
            </a>
          </button>
          <button
            className="btn outline-btn hero-btn pitch-deck-btn"
            type="button"
            onMouseOver={handleOnMouseOver}
            onMouseOut={handleOnMouseOut}
            style={{
              width: isSmallScreen && '100px',
              fontSize: isSmallScreen && '1.2rem',
              backgroundColor: mouseOver ? '#0066ff' : '#ffffff',
            }}
          >
            <a
              target="_blank"
              rel="noreferrer"
              className="btn-link"
              href="/LongTerm_Finance.pdf"
              style={{
                fontWeight: 'bold',
                fontSize: isSmallScreen ? '0.83rem' : '1.2rem',
              }}
            >
              <span style={{ color: mouseOver ? '#fff' : '#0066ff' }}>
                Presentation
              </span>
            </a>
          </button>
        </div>
      </div>

      <div className="intro-video">
        <iframe
          src="https://www.youtube.com/embed/ttAWWvjGkfo"
          title="YouTube Video Player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          className="mr-4 video"
          style={{
            height: "400px",
            width: "711px"
          }}
        />
      </div>
    </div>
  )
}

export default Hero
