import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import './layout.css'
import NotFoundImg from '../../assets/images/404.svg'
import { ThemeContext } from '../../state/ThemeContext'

const NotFound = () => {
  const { isDarkMode } = useContext(ThemeContext)

  return (
    <div className="container">
      <div className="not-found">
        <img src={NotFoundImg} alt="404 Not Found" className="img-404" />

        <div className="content-404">
          <h1 className="title-404 bold text-center">Oops!</h1>
          <p
            className={`desc-404 ${
              isDarkMode ? 'text-muted-dark-mode' : 'text-muted'
            } text-center`}
          >
            We couldn't find the page...
          </p>

          <button className="btn shadow-btn btn-404">
            <Link to="/" style={{ color: '#fff', fontWeight: 'bold' }}>
              Back to Homepage
            </Link>
          </button>
        </div>
      </div>
    </div>
  )
}

export default NotFound
