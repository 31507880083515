import React, { useContext } from 'react'
import './whypolygon.css'
import rocketIcon from '../..//assets/images/rocket.svg'
import lowFeesIcon from '../../assets/images/low-fees.svg'
import networkIcon from '../../assets/images/network.svg'
import { ThemeContext } from '../../state/ThemeContext'

const WhyPolygon = () => {
  const { isDarkMode } = useContext(ThemeContext)

  return (
    <div className="key-features-container">
      <h2 className="bold key-feats-title mt-1 text-center mb-3 what-is-long-title">
        Why We Chose to Launch on Polygon?
      </h2>

      <p
        className={`key-feats-subtitle text-center mb-5 ${
          isDarkMode ? 'text-muted-dark-mode' : 'text-muted'
        }`}
      >
        Even though we have plans to expand to the other networks over time,
        such as Fantom, Avalanche and Ethereum,{' '}
        <strong>here's why we've chosen Polygon PoS chain</strong> to be our
        first platform to deploy on.
      </p>

      <div className="key-features-long mb-3">
        <div className="key-feature">
          <div className="key-feat-icon text-center">
            <img
              width="100"
              height="150"
              src={rocketIcon}
              alt="Extremely Fast"
            />
          </div>
          <h4 className="key-feat-title mt-3">Extremely Fast</h4>
          <p className="key-feat-desc">
            One of the best things about Polygon is its{' '}
            <strong>
              near-instant transaction execution (typically within 2-3 seconds),
            </strong>{' '}
            which makes user experience extremely clean & smooth.
          </p>
        </div>

        <div className="key-feature">
          <div className="key-feat-icon text-center">
            <img width="100" height="150" src={lowFeesIcon} alt="Low Cost" />
          </div>
          <h4 className="key-feat-title mt-3">Low Cost</h4>
          <p className="key-feat-desc">
            Gas fees on Polygon are{' '}
            <strong>
              substantially lower when compared to Ethereum mainnet.
            </strong>{' '}
            Fees are typically just a few pennies for an average transaction,
            making it an ideal chain for everyone, including beginners.
          </p>
        </div>

        <div className="key-feature key-feature-roadmap-row">
          <div className="key-feat-icon text-center">
            <img
              width="100"
              height="150"
              src={networkIcon}
              alt="Network Effects"
            />
          </div>
          <h4 className="key-feat-title mt-3">Network Effects</h4>
          <p className="key-feat-desc">
            With more than $5 billion in TVL (as of late 2021),{' '}
            <strong>
              Polygon is a clear leader in Ethereum layer 2 scaling solutions.
            </strong>{' '}
            Its network effects and leading market position have made it a go-to
            layer 2 for many dapps and other use cases.
          </p>
        </div>
      </div>

      <div className="key-feature key-feature-roadmap-container">
        <div className="key-feature key-feature-roadmap">
          <div className="key-feat-icon text-center">
            <img
              width="100"
              height="150"
              src={networkIcon}
              alt="Network Effects"
            />
          </div>
          <h4 className="key-feat-title mt-3">Network Effects</h4>
          <p className="key-feat-desc">
            With more than $5 billion in TVL (as of late 2021),{' '}
            <strong>
              Polygon is a clear leader in Ethereum layer 2 scaling solutions.
            </strong>{' '}
            Its network effects and leading market position have made it a go-to
            layer 2 for many dapps and other use cases.
          </p>
        </div>
      </div>
      <div style={{ height: '27px' }} />
    </div>
  )
}

export default WhyPolygon
