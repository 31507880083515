import React, { useContext } from 'react'
import { ThemeContext } from '../../state/ThemeContext'
import TradingViewWidget, {
  Themes,
  IntervalTypes,
} from 'react-tradingview-widget'
import { useMediaQuery } from 'react-responsive'
import './altschart.css'

const ALTSChart = () => {
  const { isDarkMode } = useContext(ThemeContext)
  const chartMargin = useMediaQuery({
    query: '(max-width: 545px)',
  })

  return (
    <div className="alts-chart-container">
      <div className={`${chartMargin && 'chart-margin'}`} />
      <h1
        className="bold key-feats-title text-center mt-5 mb-3 what-is-long-title"
        style={{ fontSize: '2.5rem' }}
      >
        Historical Performance of ALTS
      </h1>

      <p
        className={`key-feats-subtitle text-center mb-5 ${
          isDarkMode ? 'text-muted-dark-mode' : 'text-muted'
        }`}
      >
        The chart below{' '}
        <strong>
          tracks the market cap of the entire crypto market excluding BTC and
          ETH in real time
        </strong>{' '}
        (calculated by TradingView)
      </p>

      <div className="tv-chart">
        <TradingViewWidget
          theme={isDarkMode ? Themes.DARK : Themes.LIGHT}
          locale="en"
          autosize
          symbol="CRYPTOCAP:TOTAL3"
          interval={IntervalTypes.D}
          timezone="Etc/UTC"
          /* eslint-disable-next-line */
          style="3"
          toolbar_bg="#f1f3f6"
          enable_publishing={false}
          hide_top_toolbar={true}
          hide_legend={true}
          save_image={false}
        />
      </div>
    </div>
  )
}

export default ALTSChart
