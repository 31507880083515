import React, { useContext } from 'react'
import './howtousealts.css'
import cashBagIcon from '../../assets/images/cash_bag.svg'
import ILChartIcon from '../../assets/images/il_chart.svg'
import mintIcon from '../../assets/images/mint.svg'
import compoundIcon from '../../assets/images/compound.svg'
import poolIcon from '../../assets/images/pool.svg'
import timeIcon from '../../assets/images/time.svg'
import yieldIcon from '../../assets/images/yield.png'
import borrowIcon from '../../assets/images/dai.png'
import ibIcon from '../../assets/images/ALTS_logo.png'
import { ThemeContext } from '../../state/ThemeContext'

const HowToUseALTS = () => {
  const { isDarkMode } = useContext(ThemeContext)

  return (
    <div className="key-features-container expanded-grid-container-6">
      <h2 className="bold key-feats-title text-center mb-3 how-to-use-alts-title">
        How to Use ALTS?
      </h2>

      <p
        className={`key-feats-subtitle text-center mb-5 ${
          isDarkMode ? 'text-muted-dark-mode' : 'text-muted'
        }`}
      >
        ALTS helps you{' '}
        <strong>effortlessly diversify your portfolio into altcoins,</strong>
        while having an option to keep all of your exposure to BTC and ETH.
      </p>

      <div className="key-features-how-to-use-alts mb-5">
        <div className="key-feature">
          <div className="key-feat-icon text-center">
            <img
              width="100"
              height="150"
              src={cashBagIcon}
              alt="Life-Changing Wealth"
            />
          </div>
          <h4 className="key-feat-title mt-3">Life-Changing Wealth</h4>
          <p className="key-feat-desc">
            Many cryptocurrency experts strongly believe that{' '}
            <strong>
              the entire altcoin market can 100x in the coming decade,
            </strong>{' '}
            so buying just 1 ALTS token now can lead to{' '}
            <strong>life-changing amounts of wealth</strong> for a lot of
            people.
          </p>
        </div>

        <div className="key-feature">
          <div className="key-feat-icon text-center">
            <img width="100" height="150" src={compoundIcon} alt="Buy & Hodl" />
          </div>
          <h4 className="key-feat-title mt-3">Buy & Hodl</h4>
          <p className="key-feat-desc">
            LongTerm Finance presents a simple answer to the age-old question:{' '}
            <strong>
              <i>
                What is the third best crypto asset to invest in for the long
                term, after BTC and ETH?
              </i>
            </strong>
          </p>
        </div>

        <div className="key-feature">
          <div className="key-feat-icon text-center">
            <img width="100" height="150" src={timeIcon} alt="Save Time" />
          </div>
          <h4 className="key-feat-title mt-3">Save Time</h4>
          <p className="key-feat-desc">
            ALTS helps you <strong>save many hours of research work,</strong>{' '}
            while at the same time outperforming the vast majority of investors
            and funds with{' '}
            <strong>passive and much safer investing strategy.</strong>
          </p>
        </div>

        <div className="key-feature">
          <div className="key-feat-icon text-center">
            <img width="100" height="150" src={mintIcon} alt="Mint" />
          </div>
          <h4 className="key-feat-title mt-3">Mint</h4>
          <p className="key-feat-desc">
            By depositing collateral (MATIC, WETH, renBTC, DAI, MIM or UST), you
            are able to <strong>mint new ALTS tokens</strong> and effortlessly
            maintain your exposure to both altcoins and BTC & ETH at the same
            time.
          </p>
        </div>

        <div className="key-feature">
          <div className="key-feat-icon text-center">
            <img width="100" height="150" src={poolIcon} alt="Pool" />
          </div>
          <h4 className="key-feat-title mt-3">Pool</h4>
          <p className="key-feat-desc">
            Earn the yield on your newly minted ALTS tokens by{' '}
            <strong>
              providing liquidity to the ALTS / WETH liquidity pair on SushiSwap.
            </strong>{' '}
            You can{' '}
            <strong>
              also earn the additional yield in the form of LONG governance
              tokens
            </strong>{' '}
            by depositing your LP tokens in{' '}
            <a
              href="https://app.long-term.finance/#/farms"
              target="_blank"
              rel="noreferrer"
              className="desc-link bold"
            >
              our incentivized farms.
            </a>
          </p>
        </div>

        <div className="key-feature">
          <div className="key-feat-icon text-center">
            <img
              width="100"
              height="150"
              src={ILChartIcon}
              alt="Minimize IL"
              style={{
                backgroundColor: isDarkMode && '#fffff',
              }}
            />
          </div>
          <h4 className="key-feat-title mt-3">Minimize IL</h4>
          <p className="key-feat-desc">
            Combining ETH with ALTS makes for a{' '}
            <strong>highly correlated liquidity pair,</strong> thus allowing
            liquidity providers to earn above average yields with the{' '}
            <strong>minimal exposure to the impermanent loss (IL).</strong>
          </p>
        </div>

        <div className="key-feature">
          <div className="key-feat-icon text-center">
            <img
              width="100"
              height="100"
              src={yieldIcon}
              alt="Earn Yield on Stablecoins"
            />
          </div>
          <h4 className="key-feat-title mt-3">Earn Yield on Stablecoins</h4>
          <p className="key-feat-desc">
            <strong>Deposit DAI and other stablecoins</strong> to start earning
            interest in a safe and predictable way on our protocol.{' '}
            <strong>
              Our long-term target for the sustainable deposit APY is 3% or
              more.
            </strong>
          </p>
        </div>

        <div className="key-feature">
          <div className="key-feat-icon text-center">
            <img
              width="100"
              height="100"
              src={borrowIcon}
              alt="Borrow Stablecoins Against ALTS"
            />
          </div>
          <h4 className="key-feat-title mt-3">
            Borrow Stablecoins Against ALTS
          </h4>
          <p className="key-feat-desc">
            There is{' '}
            <strong>no need to sell your precious long-term holdings</strong>{' '}
            when you can borrow DAI and other stablecoins against them at{' '}
            <strong>a guaranteed fixed interest rate of 5%.</strong>
          </p>
        </div>

        <div className="key-feature last-feature">
          <div className="key-feat-icon text-center">
            <img
              width="100"
              height="100"
              src={ibIcon}
              alt="Self-Repaying Loans"
              style={{
                border: '2px solid #0066ff',
                borderRadius: '100%',
                background: '#fff',
              }}
            />
          </div>
          <h4 className="key-feat-title mt-3">Self-Repaying Loans</h4>
          <p className="key-feat-desc">
            With LongTerm Finance, you're able to{' '}
            <strong>borrow against your LP tokens,</strong> which naturally
            accrue interest in real time from the swap fees on DEXs,{' '}
            <strong>thus making your loans self-repaying.</strong>
          </p>
        </div>
      </div>

      <div className="save-time-feature" />
      <div
        style={{
          height: '90px',
        }}
      />
    </div>
  )
}

export default HowToUseALTS
