import React, { useState, useContext } from 'react'
import renderHTML from 'react-render-html'
import './faq.css'
import { ThemeContext } from '../../state/ThemeContext'

const FAQ = () => {
  const { isDarkMode } = useContext(ThemeContext)

  const [faqClicked1, setFaqClicked1] = useState(false)
  const toggleFaqClicked1 = () => setFaqClicked1(!faqClicked1)
  const [faqClicked2, setFaqClicked2] = useState(false)
  const toggleFaqClicked2 = () => setFaqClicked2(!faqClicked2)
  const [faqClicked3, setFaqClicked3] = useState(false)
  const toggleFaqClicked3 = () => setFaqClicked3(!faqClicked3)
  const [faqClicked4, setFaqClicked4] = useState(false)
  const toggleFaqClicked4 = () => setFaqClicked4(!faqClicked4)
  const [faqClicked5, setFaqClicked5] = useState(false)
  const toggleFaqClicked5 = () => setFaqClicked5(!faqClicked5)
  const [faqClicked6, setFaqClicked6] = useState(false)
  const toggleFaqClicked6 = () => setFaqClicked6(!faqClicked6)
  const [faqClicked7, setFaqClicked7] = useState(false)
  const toggleFaqClicked7 = () => setFaqClicked7(!faqClicked7)
  const [faqClicked8, setFaqClicked8] = useState(false)
  const toggleFaqClicked8 = () => setFaqClicked8(!faqClicked8)
  const [faqClicked9, setFaqClicked9] = useState(false)
  const toggleFaqClicked9 = () => setFaqClicked9(!faqClicked9)
  const [faqClicked10, setFaqClicked10] = useState(false)
  const toggleFaqClicked10 = () => setFaqClicked10(!faqClicked10)

  const Faq = ({ question, answer, toggle, faqClicked }) => (
    <div className={`indiv-faq ${isDarkMode && 'indiv-faq-dark-mode'}`}>
      <div className="indiv-faq-title-container" onClick={toggle}>
        <h4 className="indiv-faq-question text-left">{question}</h4>
        <i
          className={`faq-arrow fa fa-chevron-${faqClicked ? 'up' : 'down'}`}
        />
      </div>
      {faqClicked && <p className="indiv-faq-answer text-left">{answer}</p>}
    </div>
  )

  return (
    <div
      className="faq-container container col-lg-6 col-md-8 col-sm-9"
      id="faq"
    >
      <div
        style={{
          height: '159px',
        }}
      />
      <h1
        className="text-center faq-title-main bold"
        style={{ borderBottom: isDarkMode && '1px solid #999' }}
      >
        Frequently Asked Questions
      </h1>
      <Faq
        question="1.) What is LongTerm Finance?"
        answer={renderHTML(
          `<strong>LongTerm Finance is a DeFi protocol that enables you to simplify your crypto investing with ALTS,</strong> a token that tracks the entire crypto market excluding BTC and ETH. This helps you easily diversify your portfolio and at the same time <strong>get the altcoin market's superior performance in one click.</strong>`,
        )}
        toggle={toggleFaqClicked1}
        faqClicked={faqClicked1}
      />
      <Faq
        question="2.) What are the benefits of investing in ALTS?"
        answer={renderHTML(
          `<strong>The simplicity and extremely low costs of investing into ALTS offer many benefits to both retail and institutional investors:</strong> it de-risks (hedges) your crypto portfolio by enabling you to have exposure to both altcoin market and BTC & ETH at the same time
          and helps you <strong>effortlessly diversify and use the proven passive investment strategy</strong> that will outperform most investors and funds, while at the same time being <strong>much safer compared to picking your own altcoins.</strong> Finally, <strong>it saves you 
          countless hours</strong> that would otherwise be spent on research.`,
        )}
        toggle={toggleFaqClicked2}
        faqClicked={faqClicked2}
      />
      <Faq
        question="3.) What are the risks of investing in ALTS?"
        answer={renderHTML(
          `<strong>Even though ALTS has many benefits for long term investors, there are still some risks that need to be mentioned here.</strong>
           The most important risk is <strong>the smart contract risk, specifically because our contracts are not officially audited yet.
           </strong> Many contracts in our protocol are forked from reputable DeFi protocols like Synthetix, Compound and Cryptex, which are all audited themselves. 
           <strong>We plan to complete an official smart contract audit some time in 2022.</strong> Also, there is <strong>the risk of de-pegging,</strong> 
           i.e. ALTS token not following the intended price levels. <strong>Luckily, this is easily solved by providing an arbitrage opportunity:</strong> 
           if ALTS ever gets under-pegged or over-pegged, it presents an arbitrage opporunity for traders, and this
           mechanism will quickly get ALTS price back to where it should be, <strong>thanks to it always being provably overcollateralized by high-quality 
           crypto assets.</strong> Finally, there is the risk of insufficient liquidity for ALTS and LONG trading pairs, which is expected to be gradually solved 
           over time without any interventions from the core team, primarily because of <strong>the LONG liquidity incentives, as well as the increased adoption and utility
           for both tokens.</strong>`,
        )}
        toggle={toggleFaqClicked3}
        faqClicked={faqClicked3}
      />
      <Faq
        question="4.) What is ALTS backed by?"
        answer={renderHTML(
          '<strong>Each ALTS token is overcollateralized by more than 150% corresponding amount of MATIC, WETH, renBTC, DAI, MIM or UST.</strong>',
        )}
        toggle={toggleFaqClicked4}
        faqClicked={faqClicked4}
      />
      <Faq
        question="5.) Who is ALTS for?"
        answer={renderHTML(
          `<strong>ALTS is mainly intended to be purchased as a buy & hold investment for the long term oriented investors who are generally bullish on the altcoin market</strong> and for those who
          simply don't want to pick individual altcoins themselves, but still want <strong>broad exposure to this sector.</strong> The other target group of investors includes <strong>those who are
          overweight BTC and/or ETH</strong> and want to diversify and hedge their risks with the broad altcoin market index. It’s also for DeFi users that want to <strong>earn fees and liquidity mining rewards</strong> by minting 
          ALTS tokens and providing liquidity on decentralized exchanges (DEXs) like SushiSwap and QuickSwap. Combining ETH or BTC with ALTS makes for a highly correlated liquidity pair, thus allowing liquidity providers to <strong>earn above 
          average yields with the minimal exposure to the impermanent loss.</strong> Also, <strong>ALTS represents a measuring tool and a valuable market indicator.</strong>`,
        )}
        toggle={toggleFaqClicked5}
        faqClicked={faqClicked5}
      />
      <Faq
        question="6.) How is ALTS able to track the altcoin market?"
        answer={renderHTML(
          `<strong>ALTS uses oracle solutions which enable LongTerm Finance to aggregate multiple data points</strong> from the top crypto market data providers in the world, and brings that data on chain using <strong>Chainlink's oracle smart contracts.</strong> Also, if the price of ALTS ever deviates from the current ALTS price supplied by the Chainlink oracle, this will present <strong>a free market arbitrage opportunity</strong> which will very quickly bring the ALTS price back to its intended levels.`,
        )}
        toggle={toggleFaqClicked6}
        faqClicked={faqClicked6}
      />
      <Faq
        question="7.) What is LONG token?"
        answer={renderHTML(`
        As a native governance and utility token, LONG represents a lifeblood of the entire LongTerm Finance ecosystem. It features a <strong>robust and carefully designed 
          <a 
            className="desc-link bold"
            target="_blank"
            rel="noreferrer"
            href="https://docs.long-term.finance/governance/long-token#tokenomics"
          >
          tokenomics
          </a></strong> with the max supply of 10 million. In addition to having favorable tokenomics, 
          it has <strong>many use cases that will ensure the constant rise in demand</strong> for LONG tokens over time, and consequently increase its valuation.`)}
        toggle={toggleFaqClicked7}
        faqClicked={faqClicked7}
      />
      <Faq
        question="8.) What are the use cases for LONG token?"
        answer={renderHTML(
          `Two primary use cases for the LONG token include 
          <strong>liquidity incentives</strong> (for the first 3 
          years following the launch of the protocol) and <strong>voting in the 
          protocol's decentralized governance</strong> 
          (where you can vote, propose, decide and debate changes
          to the protocol). Also, you can <strong>buy & hold LONG tokens to enjoy the discounted burn fee rates.</strong> Finally, one of
          the key demand drivers for the LONG token is also <strong>the monthly
          buyback & burn event,</strong> in which we'll take 75% 
          of the protocol's earnings for that month, swap them for 
          the LONG tokens, and finally <strong>burn them for good, thus 
          decreasing the token's circulating supply.</strong>`,
        )}
        toggle={toggleFaqClicked8}
        faqClicked={faqClicked8}
      />
      <Faq
        question="9.) What are your long term plans?"
        answer={renderHTML(
          `ALTS is only the beginning for the LongTerm Finance. <strong>Our long-term
          vision is to become the <i>Vanguard of the crypto industry</i> by offering various
          simple, low-cost & passive investment strategies in the form of token indices.</strong> Visit
            <a
              className="desc-link bold"
              target="_blank"
              rel="noreferrer"
              href="https://docs.long-term.finance/protocol/future-plans"
            >
              our future plans documentation
            </a>
            to see more detailed information about how we plan to achieve this.
          `,
        )}
        toggle={toggleFaqClicked9}
        faqClicked={faqClicked9}
      />
      <Faq
        question="10.) Where can I learn more about ALTS and LongTerm Finance?"
        answer={renderHTML(`
          We highly recommend you start by going through the 
          <strong>
          <a 
            className="desc-link bold"
            target="_blank"
            rel="noreferrer"
            href="https://app.long-term.finance/#/about"
          > 
            About section
          </a> of the LongTerm Finance dashboard.</strong> There is more than enough information there
          to get you started, and <strong>it will take less than 5 minutes to go through it.</strong> For more detailed info, you can always refer
          to 
          <a
            className="desc-link bold"
            target="_blank"
            rel="noreferrer"
            href="https://docs.long-term.finance"
          > 
            our official documentation.
          </a> <strong>To keep up with the news and announcements</strong> from LongTerm Finance, you can follow us on 
          <a
            className="desc-link bold"
            target="_blank"
            rel="noreferrer"
            href="https://twitter.com/LongTermFi"
          >
            Twitter
          </a> and join our
          <a
            className="desc-link bold"
            target="_blank"
            rel="noreferrer"
            href="https://t.me/joinchat/_-xv_PVkLTJlNGJk"
          >
            Telegram group.
          </a> Finally, if you have some specific questions (like <strong>partnership or collaboration requests</strong>), please contact us directly via email at
          <a 
            className="desc-link bold"
            href="mailto:contact@long-term.finance"
            target="_blank"
            rel="noreferrer"
          >
            contact@long-term.finance
          </a>
        `)}
        toggle={toggleFaqClicked10}
        faqClicked={faqClicked10}
      />
    </div>
  )
}

export default FAQ
