import React, { Suspense, useContext } from 'react'
import { Route, Switch, HashRouter } from 'react-router-dom'
import Spinner from './components/Layout/Spinner'
import ErrorBoundary from './components/Layout/ErrorBoundary'
import './App.css'
import Navbar from './components/Navbar/Navbar'
import Hero from './components/Hero/Hero'
import HowToUseALTS from './components/HowToUseALTS/HowToUseALTS'
import ALTSChart from './components/ALTSChart/ALTSChart'
import WhatIsLONG from './components/WhatIsLONG/WhatIsLONG'
import WhyPolygon from './components/WhyPolygon/WhyPolygon'
import FAQ from './components/FAQ/FAQ'
import Links from './components/Links/Links'
import NotFound from './components/Layout/NotFound'
import { ThemeContext } from './state/ThemeContext'

const App = () => {
  const { isDarkMode } = useContext(ThemeContext)

  const HomePage = () => {
    return (
      <div className="container">
        <Hero />
        <HowToUseALTS />
        <ALTSChart />
        <WhatIsLONG />
        <WhyPolygon />
      </div>
    )
  }

  return (
    <HashRouter>
      <ErrorBoundary>
        <Suspense fallback={<Spinner />}>
          <div className={isDarkMode ? 'dark-mode' : 'light-mode'}>
            <Navbar />
            <Switch>
              <Route exact path="/" component={HomePage} />
              <Route exact path="/faq" component={FAQ} />
              <Route component={NotFound} />
            </Switch>
            <Links />
          </div>
        </Suspense>
      </ErrorBoundary>
    </HashRouter>
  )
}

export default App
